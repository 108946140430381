import type { FC } from 'react'
import styled from 'styled-components'

import { useGetConfigurationQuery } from '@reduxCommonApp/api/endpoints'

import { ConfigurationFrontendTypes } from '@constants/configuration/configuration'

import ItemInfo from './ItemInfo/ItemInfo'

const Info: FC = () => {
  const { data } = useGetConfigurationQuery(ConfigurationFrontendTypes.FOOTER)

  if (!data?.blocks) return

  return (
    <Container>
      {Object.values(data.blocks).map((info, i) => (
        <ItemInfo {...info} key={i} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  min-height: 200px;
  max-width: ${p => p.theme.sizes.contentMaxWidth}px;
  margin: 0 auto;
  padding: 48px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  grid-gap: 50px;
  position: relative;

  ${p => p.theme.mediaQueries.desktop} {
    padding: 40px 20px;
    grid-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
  }

  ${p => p.theme.mediaQueries.mobile} {
    grid-template-columns: 100%;
    grid-gap: 18px;
    padding: 32px 20px;
    grid-area: info;
  }
`

export default Info
